<template>

  <router-view />
  <nut-tabbar bottom safe-area-inset-bottom placeholder @tab-switch="tabSwitch">
    <nut-tabbar-item v-for="(item,index) in List" :key="index" :tab-title="item.title" :icon="item.icon" :to="item.to"> </nut-tabbar-item>
  </nut-tabbar>
  
</template>

<script>
  import { h } from 'vue';
  import { Home, Category, Find, Cart, My, Message, Order , Add , Setting, Service} from '@nutui/icons-vue';
  

  export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { Home, Category, Find, Cart, My , Message, Order , Add , Setting, Service },
    setup() {
      const List = [
        {
          title: '主页',
          icon: h(Home),
          to: '/home',
        },
        {
          title: '通知信息',
          icon: h(Message),
          to: '/message'
        },
        {
          title: '个人',
          icon: h(My),
          to:  '/user'
        }
      ];
      // tab切换
      const tabSwitch = (item,index) => {
        console.log(item,index);
      };
      return {
        List,
        tabSwitch
      };
    }
  };
</script>